import React from 'react'
import Layout from '../../components/layout'
import SEO from "../../components/seo"
import Contact from "../../components/sections/Contact"
import Services from '../../components/sections/Services'

const ProductDesign = () => {
  return (
    <>
     <SEO title="Product Design - Digimeth.net"/>
     <Layout>
        <article className='co-page'>
           <section className="typical__padding co-bullet">
               <div className="section-container">
                   <h2 className="section-title">Product Designer</h2>
                   <p className="co-bullet-info">
                       We are a team of payment technologies seeking passionate professionals to reach new heights. DigiMeth is currently in search of a Product Designer.                   </p>
                   <div className="co-vacancies--grid">
                       <h3 className='co-bullet-title'>About DigiMeth</h3>
                       <p className="co-bullet-info">
                           Digital Method is a software development company specialising in payment technology. Our mission is to provide fast, high-quality, and diverse online payment experience to our partners and their customers. We provide international and local payments for all business types.                       </p>
                       <h3 className='co-bullet-title'>What You’ll Do</h3>
                       <ul className='co-bullet-list'>
                           <li>Collaborate with cross-functional teams to develop product ideas, concepts, and features.</li>
                           <li>Conduct user research and analyze user feedback to identify pain points and opportunities for improvement.</li>
                           <li>Create wireframes, prototypes, and visual designs that effectively communicate design concepts and product functionality.</li>
                           <li>Ensure design work is user-centred, meets business objectives, and aligns with our brand guidelines.</li>
                           <li>Continually iterate and improve designs based on user feedback and testing.</li>
                           <li>Work closely with developers to ensure that designs are implemented accurately and effectively.</li>
                       </ul>

                       <h3 className='co-bullet-title'>What you`ll need</h3>
                       <ul className='co-bullet-list'>
                           <li>At least 2 years of experience in the design field.</li>
                           <li>Experience in designing mobile applications for iOS and Android.</li>
                           <li>Proficiency in design tools such as Figma or Adobe Creative Suite for web and mobile platforms.</li>
                           <li>Confident in translating UX and product strategy, user and market research into consistent user flows and simple and beautiful User Interface.</li>
                           <li>Knowledge of design standards (Material, Apple Human Interface Guidelines, etc.).</li>
                           <li>Suggestion on modern ideas of information presentation and catchy designs.</li>
                           <li>At least Upper-Intermediate spoken and written English level: communication on the design suggestions and the presentation of design solutions is required.</li>
                       </ul>

                       <p className="co-bullet-info">Work schedule - 5/2, 09:00-18:00 (aligned with banking system operations).</p>

                       <div className='co-join-container'>
                           <span>If you are interested in working with us, send your CV here</span>{' '}
                           <a href="mailto:contact@digimeth.net">contact@digimeth.net</a>
                       </div>
                   </div>
               </div>
           </section>
           <Services title="Work at Digimeth Feels Perfect!" desc="Our goal is to create fantastic payment solutions:" />
           <Contact/>
       </article>
   </Layout>
    </>
  
  )
}

export default ProductDesign 